import React, { useEffect } from 'react'
import { ButtonContainer, ClientsTextBox, FadingImage, Header2, ImageContainer, LeftColumn, LeftColumnPurpose, PurposeSection, RightColumn, RightColumnPurpose } from './styles';
import { About } from '../../components/About';
import ServicesBG from '../../assets/Backgrounds/nosotros-bg.png';
import { BusinessInfoCard } from '../../components/BusinessInfoCard';
import theme from '../../theme/theme';
import { LogoCarousel } from '../../components/LogoCarousel';
import { Box, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChatBotContainer } from '../Home/styles';
import { ChatBotButton } from '../../components/ChatBotButton';
import Video from '../../components/Video/Video';

export const Services: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Siempre que cambie la ubicación, desplázate al inicio de la página
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <ImageContainer>
                <LeftColumn>
                    <FadingImage src={ServicesBG} />
                </LeftColumn>
                <RightColumn>
                    <About
                        title='Nosotros'
                        description='Nuestro multidisciplinario equipo de expertos, diseña e implementa
                        soluciones dirigidas hacia el manejo eficiente de la energía eléctrica,
                        redundando en ahorros monetarios y una red eléctrica confiable.
                        
                        Enfocamos nuestros esfuerzos en centros de carga conectados en
                        media tension, resolviendo los retos de calidad, consumo, monitoreo
                        y control de la energía eléctrica; acompañando a nuestros clientes en
                        cada reto encomendado y dictando las directrices para llevar a buen
                        término las oportunidades de mejora y la resolución de problemas
                        financieros, operativos y legales en el tema de la Gestión de la energía
                        eléctrica. Cumpliendo los requerimientos más exigentes de nuestros
                        clientes.'
                        description2=''
                    />
                </RightColumn>
            </ImageContainer>
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    color: theme.color.black,
                    backgroundColor: theme.color.pearl,

                }}
            >
                <Header2 backgroundColor={theme.color.pearl}>
                    ¿Por qué UPElectric?
                </Header2>
            </Box>
            <PurposeSection>
                <LeftColumnPurpose>
                    <BusinessInfoCard
                        title='Perfil profesional'
                        description={`
                        [Ahorramos]. Con la Certeza de Cómo, Cuánto, y el tiempo de retorno de su inversión.
                        
                        [Corregimos]. Enfocándonos en la mejor manera de sobrepasar el reto eficientemente.
                        
                        [Defendemos]. Brindándole la justificación técnica haciendo el enlace con el fundamento legal aplicable según la normativa vigente.
                        
                        [Innovamos]. Creamos soluciones de ingeniería utilizando tecnología de punta, que le permitirá lograr sus objetivos aún cuando la solución no exista en el mercado.
                        
                        Más que un [proveedor], nos convertimos en su [consultor] de confianza, brindando acompañamiento y soluciones adecuadas a su presupuesto y condiciones de operación.
                        `}
                        dividerColor={theme.color.indigo}
                    />
                </LeftColumnPurpose>
                <RightColumnPurpose>
                    <Video
                      videoLink="https://www.youtube.com/embed/TFhRXnE2xck"
                      width= "100%"
                      height= "100%"
                    />  
                </RightColumnPurpose>
            </PurposeSection>
            <Header2>
                Algunos de nuestros representantes
            </Header2>
            <ClientsTextBox>
                Con el respaldo directo de los fabricantes de las marcas líderes en el mercado eléctrico
                especializado, entregamos a nuestros clientes proyectos “llave en mano” que funcionan sin
                contratiempos.
            </ClientsTextBox>
            <LogoCarousel
                clients={[
                    {
                        src: 'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                        alt: 'ITESM',
                        client: 'ITESM',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.',
                        images: [
                            'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                            'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                            'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                            'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                        ]
                    },
                    {
                        src: 'https://img.freepik.com/premium-vector/autumn-mountains-landscape-with-tree-silhouettes-river-sunset_148087-293.jpg?w=2000',
                        alt: 'ITESM',
                        client: 'Lilly',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                    },
                    {
                        src: 'https://www.adorama.com/alc/wp-content/uploads/2018/11/landscape-photography-tips-yosemite-valley-feature.jpg',
                        alt: 'ITESM',
                        client: 'ABC',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                    },
                    {
                        src: 'https://images.unsplash.com/photo-1500964757637-c85e8a162699?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YmVhdXRpZnVsJTIwbGFuZHNjYXBlfGVufDB8fDB8fHww&w=1000&q=80',
                        alt: 'ITESM',
                        client: 'ITAM',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                    },
                    {
                        src: 'https://img.freepik.com/free-vector/nature-scene-with-river-hills-forest-mountain-landscape-flat-cartoon-style-illustration_1150-37326.jpg?w=2000',
                        alt: 'ITESM',
                        client: 'UVM',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                    },
                    {
                        src: 'https://cdn-ajggd.nitrocdn.com/kMoOFpDlsOVtlYJLrnSRNCQXaUFHZPTY/assets/images/optimized/rev-814242f/wp-content/uploads/bb-plugin/cache/night-landscape-photography-featured-landscape-7adbbe52e2e7586f094047236f31999a-zybravgx2q47.jpg',
                        alt: 'ITESM',
                        client: 'Caprice',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                    },
                ]}
            />
            <ButtonContainer>
                <Button
                    variant="contained"
                    sx={{
                        width: '300px',
                        // height: '60px',
                        borderRadius: '12px',
                        fontSize: '18px',
                        backgroundColor: theme.color.honey,
                        color: theme.color.white,
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: theme.color.apricot,
                        }
                    }}
                    onClick={() => navigate("/contact")}
                >
                    Contáctanos
                </Button>
            </ButtonContainer>
            <ChatBotContainer>
                <ChatBotButton />
            </ChatBotContainer>
        </>
    )
}

export default Services;