import { FiberManualRecord } from '@mui/icons-material';
import styled, { css } from 'styled-components';
import theme from '../../theme/theme';

export const ProjectsWrapper = styled.div<{ light?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  color: ${({ light }) => (light ? theme.color.black : theme.color.white)};
  background-color: ${({ light }) => (light ? theme.color.pearl : theme.color.anchor)};
  padding: 2rem 4rem; 

  @media (max-width: 1024px) {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem 2rem;
  }
`;

export const ProjectContainer = styled.div<{ height: string; width: string }>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    transition: transform 0.3s ease;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    gap: 10px;
`;

export const Project = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    gap: 10px;

    &.fade-out {
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    &.fade-in {
      opacity: 1;
      transition: opacity 0.5s ease;
    }
    
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 auto;
        gap: 10px;
    }
`;

export const ProjectClient = styled.h2`
  font-size: 46px;
  font-weight: 800;
  line-height: 4rem;
  margin: 0;
`;

export const ProjectBody = styled.p`
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
  margin: 0;
`;


export const ArrowButton = styled.button<{ left?: boolean; right?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 24px;
  transition: opacity 0.3s, transform 0.3s;
  opacity: 1.0;
  transform: translateX(0);

  &:hover {
    opacity: 1.0;
    transform: translateX(${({ left }) => (left ? '-5px' : '5px')});
  }
`;

export const PaginationContainer = styled.div<{ light?: boolean }>`
    display: flex;
    justify-content: center;
    color: ${({ light }) => (light ? theme.color.black : theme.color.white)};
    background-color: ${({ light }) => (light ? theme.color.pearl : theme.color.anchor)};
    padding-bottom: 2rem;
`;

export const CirclesContainer = styled.div<{ light?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  color: ${({ light }) => (light ? theme.color.black : theme.color.white)};
  background-color: ${({ light }) => (light ? theme.color.pearl : theme.color.anchor)};
`;

export const Circle = styled(FiberManualRecord) <{ active: boolean; light: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  margin: 0 2px;
  color: ${({ light }) => (light ? theme.color.black : theme.color.white)};
`;
