import { Divider, IconButton, Typography } from '@mui/material'
import React from 'react'
import { ProjectDescriptionProps } from './types'
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'
import { ArrowButton, CirclesContainer, Circle, PaginationContainer, Project, ProjectContainer, ProjectsWrapper, ProjectClient, ProjectBody } from './styles'
import { ImageMosaic } from '../ImageMosaic'
import theme from '../../theme/theme'
import { SingleGallery } from '../SingleGallery'

const ProjectDescription: React.FC<ProjectDescriptionProps> = ({
    projects
}) => {
    const [currentProject, setCurrentProject] = React.useState(0);
    const [fadeOut, setFadeOut] = React.useState(false);
    const [fadeIn, setFadeIn] = React.useState(false);
    const [isSmallScreen, setIsSmallScreen] = React.useState(false);

    const handlePrevClick = () => {
        setFadeOut(true);
        setTimeout(() => {
            setCurrentProject((prevProject) =>
                (prevProject === 0 ? projects.length - 1 : prevProject - 1));
            setFadeOut(false);
        }, 300);
    };

    const handleNextClick = () => {
        setFadeOut(true);
        setTimeout(() => {
            setCurrentProject((prevProject) =>
                (prevProject + 1) % projects.length);
            setFadeOut(false);
        }, 300);
    };

    const currProj = currentProject;
    const visibleProject = [];
    const projectIndex = (currProj + 1) % projects.length;
    visibleProject.push(projects[projectIndex]);

    React.useEffect(() => {
        setFadeIn(true);
    }, [currentProject]);

    React.useEffect(() => {
        const handleScreenSizeChange = (e: MediaQueryListEvent) => {
            setIsSmallScreen(e.matches);
        };

        const mediaQueryList = window.matchMedia('(max-width: 600px)'); // Adjust breakpoint if needed
        setIsSmallScreen(mediaQueryList.matches);
        mediaQueryList.addEventListener('change', handleScreenSizeChange);

        return () => {
            mediaQueryList.removeEventListener('change', handleScreenSizeChange);
        };
    }, []);

    return (
        <>
            {visibleProject.map((project, index) => (
                <ProjectsWrapper light={projectIndex % 2 === 0}>
                    <ProjectContainer width='80%' height='auto'>
                        <Project
                            className={fadeOut ? 'fade-out' : '' || fadeIn ? 'fade-in' : ''}
                            onTransitionEnd={() => setFadeOut(false)}
                        >
                            <ProjectClient>
                                {project.client}
                            </ProjectClient>
                            <ProjectBody>
                                {project.projectDescription}
                            </ProjectBody>
                            {isSmallScreen ? (
                                // Render SingleGallery for small screens
                                project.images && <SingleGallery images={project.images} />
                            ) : (
                                // Render ImageMosaic for larger screens
                                project.images && <ImageMosaic images={project.images} />
                            )}
                        </Project>
                    </ProjectContainer>
                </ProjectsWrapper>
            ))
            }
            <PaginationContainer light={projectIndex % 2 === 0}>
                <ArrowButton onClick={handlePrevClick} left>
                    <IconButton>
                        <ArrowBackIosNew style={projectIndex % 2 === 0 ? { color: theme.color.black, fontSize: 15 } : { color: theme.color.pearl, fontSize: 15 }} />
                    </IconButton>
                </ArrowButton>
                <CirclesContainer light={projectIndex % 2 === 0}>
                    {projects.map((_, index) => (
                        <Circle key={index} active={index === currentProject} light={projectIndex % 2 === 0} style={{ fontSize: 9 }} />
                    ))}
                </CirclesContainer>
                <ArrowButton onClick={handleNextClick} right>
                    <IconButton>
                        <ArrowForwardIos style={projectIndex % 2 === 0 ? { color: theme.color.black, fontSize: 15 } : { color: theme.color.pearl, fontSize: 15 }} />
                    </IconButton>
                </ArrowButton>
            </PaginationContainer>
        </>
    )
}

export default ProjectDescription
