import styled from "styled-components";
import theme from "../../theme/theme";

export const AboutWrapper = styled.div`
  display: flex;
  max-width: 690px;
  flex-direction: column;
  padding: 2em 2em 0 2em;
  font-family: "Inter", sans-serif;

  @media (max-width: 600px) {
    padding: 0;
    padding-top: 2em;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: -0.5em;
  margin-left: -20px;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

export const TextLogoContainer = styled.div`
    display: inline-block;
`;

export const UPText = styled.span`
  color: ${theme.color.honey};
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;  

`;

export const ElectricText = styled.span`
  color: ${theme.color.white};
  font-size: 20px;
  font-weight: 700;
  line-height: 20px; 
`;

export const Title = styled.h1`
  color: ${theme.color.white};
  font-size: 72px;
  font-weight: 900;
  line-height: 98px;
  margin-top: -0.3em;
  
  @media (max-width: 400px) {
    font-size: 60px;
  }
`;

export const Description = styled.p`
  color: ${theme.color.white};
  font-size: 20px;
  font-weight: 300;
  line-height: 40px;
  margin-top: -1.5em;
  margin-bottom: 2.5em;

  @media (max-width: 600px) {
    margin-top: -1em;
    margin-bottom: 1em;
    line-height: 30px;
  }
`;

export const LogoImgContainer = styled.div`
    margin: 1em 0 1em 1em;
    
    @media (max-width: 1024px) {
        margin: 0 0 0 0;
    }
`;

export const LogoImg = styled.img`
    height: auto;
    width: 25px;
`;