import React from 'react'
import { VideoProps } from './types'
import { VideoContainer, VideoIframe } from './styles';

export const Video: React.FC<VideoProps> = ({
  videoLink,
  width='270px',
  height='480px',
  title,
  autoplay,
}) => {
  return (
    <VideoContainer
      height={height}
      width={width}
    >
      <VideoIframe
        src={videoLink + (autoplay ? '?autoplay=1' : '')}
        height={height}
        width={width}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        title={title || 'YouTube video'}
        allowFullScreen
        frameBorder="0"
      />
    </VideoContainer>
  );
};

export default Video;
