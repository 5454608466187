import React, { useEffect } from 'react';
import LandingBG from '../../assets/Backgrounds/landing-bg_v2.jpg';
import { AutomText, BrandContainer, BrandDescription, BusinessSection, ButtonContainer, ButtonsContainer, ChallengesHeader, ChallengesSpan, ChatBotContainer, ElectricText, FadingImage, Header2, HomeWrapper, ImageContainer, LogoImg, LogoImgContainer, MainTitle, SolutionsSection, TextLogoContainer, UPText } from './styles';
import UPElectricLogo from "../../assets/NoTextLogo.png";
import { Box, Button, Typography } from '@mui/material';
import theme from '../../theme/theme';
import { BusinessInfoCard } from '../../components/BusinessInfoCard';
import { Info } from '../../components/Info';
import { ImageGallery } from '../../components/ImageGallery';
import { LogoCarousel } from '../../components/LogoCarousel';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChatBotButton } from '../../components/ChatBotButton';

const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Siempre que cambie la ubicación, desplázate al inicio de la página
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <HomeWrapper>
                <ImageContainer>
                    <FadingImage src={LandingBG} width='100%' alt="testing" />
                    <BrandDescription>
                        <BrandContainer>
                            <LogoImgContainer>
                                <LogoImg src={UPElectricLogo} />
                            </LogoImgContainer>
                            <TextLogoContainer>
                                <UPText>UP</UPText>
                                <ElectricText>Electric</ElectricText>
                                <AutomText>Automatización</AutomText>
                            </TextLogoContainer>
                        </BrandContainer>
                        <MainTitle>
                            Expertos en gestión inteligente de la energía eléctrica
                        </MainTitle>
                        <ButtonsContainer>
                            <Button
                                variant="contained"
                                sx={{
                                    width: '300px',
                                    // height: '60px',
                                    borderRadius: '12px',
                                    fontSize: '18px',
                                    backgroundColor: theme.color.honey,
                                    color: theme.color.white,
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: theme.color.apricot,
                                    }
                                }}
                                onClick={() => navigate("/services")}
                            >
                                Servicios
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    width: '300px',
                                    // height: '60px',
                                    borderRadius: '12px',
                                    fontSize: '18px',
                                    backgroundColor: theme.color.pearl,
                                    marginBottom: "18px",
                                    color: theme.color.black,
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: theme.color.silver,
                                    }
                                }}
                                onClick={() => navigate("/contact")}
                            >
                                Contáctanos
                            </Button>
                        </ButtonsContainer>
                    </BrandDescription>
                </ImageContainer>
                <BusinessSection>
                    <BusinessInfoCard
                        width='50%'
                        title='UPElectric Automatización S.A. de C.V.'
                        variant='about'
                        dividerColor={theme.color.graphite}
                        backgroundColor={theme.color.pearl}
                        image='https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954'
                        description={`Somos una empresa integrada por un equipo de ingenieros y técnicos expertos en gestión inteligente de la energía eléctrica.
                    Atendemos a los grandes consumidores de la electricidad, orientándolos a eficientar el 60% de la energía consumida en México.
                    Con 25 años de experiencia y nuestro perfil profesional acreditado, entregamos soluciones innovadoras y de impacto positivo en las redes eléctricas de nuestros clientes.`}
                        zIndex={2}
                    />
                </BusinessSection>
                <ChallengesHeader>
                    Resolvemos los retos en el sector eléctrico.
                    <ChallengesSpan>
                        Orientamos nuestras soluciones bajo 3 perspectivas:
                    </ChallengesSpan>
                </ChallengesHeader>
                <SolutionsSection>
                    <Info
                        type="Legal"
                        content="Nos aseguramos de cumplir con las [normativas vigentes] y evitar [sanciones innecesarias] para su empresa, incluyendo [multas por bajo factor de potencia], cumplimiento [STPS], [Código de red] y aclaraciones de [facturación CFE], entre otros."
                    />
                    <Info
                        type="Operativo"
                        content="Brindamos soluciones integrales para problemas de ruido eléctrico, [distorsión armónica], [desbalance] entre fases y otros inconvenientes derivados de la mala calidad de energía. Ofrecemos plataformas de [alto rendimiento] a bajo costo para el [monitoreo] en tiempo real de parámetros eléctricos, control de demanda máxima [(Pick Shaving)], y una amplia gama de servicios de mantenimiento correctivo y preventivo para sus centros de carga."
                    />
                    <Info
                        type="Financiero"
                        content="Enfocados en obtener [ahorros monetarios] para su empresa por el uso adecuado de la Energía Eléctrica, implementamos estrategias ([tarificación, facturación, control de la energía]) comprobadas y dirigidas hacia el objetivo de obtener el máximo provecho de cada “kw” pagado a CFE."
                    />
                </SolutionsSection>
                <Box
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        color: theme.color.white,
                        backgroundColor: theme.color.graphite,

                    }}
                >
                    <Header2 color={theme.color.white} backgroundColor={theme.color.graphite}>
                        Galería
                    </Header2>
                </Box>
                <ImageGallery
                    images={[
                        'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                        'https://img.freepik.com/premium-vector/autumn-mountains-landscape-with-tree-silhouettes-river-sunset_148087-293.jpg?w=2000',
                        'https://www.adorama.com/alc/wp-content/uploads/2018/11/landscape-photography-tips-yosemite-valley-feature.jpg',
                        'https://images.unsplash.com/photo-1500964757637-c85e8a162699?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YmVhdXRpZnVsJTIwbGFuZHNjYXBlfGVufDB8fDB8fHww&w=1000&q=80',
                        'https://img.freepik.com/free-vector/nature-scene-with-river-hills-forest-mountain-landscape-flat-cartoon-style-illustration_1150-37326.jpg?w=2000',
                        'https://cdn-ajggd.nitrocdn.com/kMoOFpDlsOVtlYJLrnSRNCQXaUFHZPTY/assets/images/optimized/rev-814242f/wp-content/uploads/bb-plugin/cache/night-landscape-photography-featured-landscape-7adbbe52e2e7586f094047236f31999a-zybravgx2q47.jpg',
                    ]}
                />
                <Header2>
                    Algunos de nuestros clientes
                </Header2>
                <LogoCarousel
                    clients={[
                        {
                            src: 'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                            alt: 'ITESM',
                            client: 'ITESM',
                            projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.',
                            images: [
                                'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                                'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                                'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                                'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                            ]
                        },
                        {
                            src: 'https://img.freepik.com/premium-vector/autumn-mountains-landscape-with-tree-silhouettes-river-sunset_148087-293.jpg?w=2000',
                            alt: 'ITESM',
                            client: 'Lilly',
                            projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                        },
                        {
                            src: 'https://www.adorama.com/alc/wp-content/uploads/2018/11/landscape-photography-tips-yosemite-valley-feature.jpg',
                            alt: 'ITESM',
                            client: 'ABC',
                            projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                        },
                        {
                            src: 'https://images.unsplash.com/photo-1500964757637-c85e8a162699?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YmVhdXRpZnVsJTIwbGFuZHNjYXBlfGVufDB8fDB8fHww&w=1000&q=80',
                            alt: 'ITESM',
                            client: 'ITAM',
                            projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                        },
                        {
                            src: 'https://img.freepik.com/free-vector/nature-scene-with-river-hills-forest-mountain-landscape-flat-cartoon-style-illustration_1150-37326.jpg?w=2000',
                            alt: 'ITESM',
                            client: 'UVM',
                            projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                        },
                        {
                            src: 'https://cdn-ajggd.nitrocdn.com/kMoOFpDlsOVtlYJLrnSRNCQXaUFHZPTY/assets/images/optimized/rev-814242f/wp-content/uploads/bb-plugin/cache/night-landscape-photography-featured-landscape-7adbbe52e2e7586f094047236f31999a-zybravgx2q47.jpg',
                            alt: 'ITESM',
                            client: 'Caprice',
                            projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                        },
                    ]}
                />
                <ButtonContainer>
                    <Button
                        variant="contained"
                        sx={{
                            width: '300px',
                            // height: '60px',
                            borderRadius: '12px',
                            fontSize: '18px',
                            backgroundColor: theme.color.honey,
                            color: theme.color.white,
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: theme.color.apricot,
                            }
                        }}
                        onClick={() => navigate("/contact")}
                    >
                        Contáctanos
                    </Button>
                </ButtonContainer>
            </HomeWrapper>
            <ChatBotContainer>
                <ChatBotButton />
            </ChatBotContainer>
        </>
    );
}

export default Home;