import styled, { keyframes } from "styled-components";
import theme from "../../theme/theme";

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const ImageContainer = styled.div`
  width: 100%;
  background-color: ${theme.color.anchor};
  display: flex;
  align-items: center;
  gap: 8rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
    align-items: unset;
    padding-top: 2rem;
  }

  @media (max-width: 400px) {
    padding-top: 0;
  }
`;

export const LeftColumn = styled.div`
  flex-basis: 45%;
  order: 1;
  @media (max-width: 768px) {
    width: 80%;
    order: 2;
    display: flex;
    justify-content: flex-start;
    margin: 0;
  } 
}`;

export const RightColumn = styled.div`
  flex-basis: 55%;
  order: 2;

  @media (max-width: 768px) {
    order: 1;
    margin: 0 10%;
    flex-basis: 100%;
  }

  @media (max-width: 400px) {
    margin: 0 9%;
  }
`;

export const FadingImage = styled.img`
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out forwards;
  width: 100%;
  height: 100%;
`;

export const Header2 = styled.h2<{ color?: string; backgroundColor?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 4rem 0;
    height: 100px;
    color: ${({ color }) => color || theme.color.black};
    background-color: ${({ backgroundColor }) => backgroundColor || theme.color.white};
    font-weight: 800,
    line-height: 4rem;
    font-size: 3rem;

    @media (max-width: 1024px) {
        font-size: 2rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 70%;
        margin: 0 auto;
        height: 80px;
    }

    @media (max-width: 600px) {
        font-size: 1.5rem;
        width: 80%;
        height: 60px;
    }
}`;

export const PurposeSection = styled.div`
    background-color: ${theme.color.pearl};
    display: flex;
    flex-direction: row;
    padding: 0 15% 4rem 15%;
    gap: 4rem;

    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 0;
        gap: 2rem;
    }
`;

export const LeftColumnPurpose = styled.div`
    flex-basis: 60%;
    @media (max-width: 768px) {
    }
`;

export const RightColumnPurpose = styled.div`
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @media (max-width: 1024px) {
      margin: 0 auto;
      flex-basis: unset;
      width: 90%;
      height: 500px;
    }
`;

export const ClientsTextBox = styled.div`
    margin: -10px 15% 2rem 15%;
    font-size: 18px;
    text-align: left;

    @media (max-width: 1024px) {
        margin: 0 1rem 1rem 1rem;
        width: 90%;
        font-size: 18px;
        line-height: 30px;
  
      @media (max-width: 600px) {
          font-size: 16px;
          line-height: 25px;
      }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 60px 0;
`;