import styled, { keyframes } from 'styled-components'
import theme from '../../theme/theme';

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const transitionUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const HomeWrapper = styled.div`
    font-family: 'Inter', sans-serif;
    position: relative;
`;

export const BrandDescription = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    gap: 40px;
    margin: 100px 0 0 100px;

    @media (max-width: 1024px) {
        padding: 0px;
        margin: 100px 50px;
    }

    @media (max-width: 600px) {
        width: 100%;
        padding: 60px 30px;
        margin: 0;
        gap: 30px;
        align-items: center;
    }
`;

export const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: 1200px) {
    margin-left: -30px;  
  }

  @media (max-width: 800px) {
    margin-left: -20px;
  }
`;

export const TextLogoContainer = styled.div`
    display: inline-block;
`;

export const UPText = styled.span`
  color: ${theme.color.honey};
  font-size: 60px;
  font-weight: 700;
  line-height: 20px;

  @media (max-width: 1200px) {
    font-size: 40px;
  }

  @media (max-width: 800px) {
    font-size: 30px;
  }
`;

export const ElectricText = styled.span`
  color: ${theme.color.white};
  font-size: 60px;
  font-weight: 700;
  line-height: 20px; 

  @media (max-width: 1200px) {
    font-size: 40px;
  }

  @media (max-width: 800px) {
    font-size: 30px;
  }
`;

export const AutomText = styled.p`
    color: ${theme.color.white};
    font-size: 26px;
    font-weight: 600;
    margin: 0;
    padding: 0;

    @media (max-width: 1200px) {
        font-size: 20px;
    }

    @media (max-width: 800px) {
        font-size: 16px;
    }
`;

export const MainTitle = styled.h2`
    color: ${theme.color.white};
    font-size: 46px;
    font-weight: 800;
    line-height: 4rem;
    margin: 0;

    @media (max-width: 1200px) {
        font-size: 40px;
        line-height: 3rem;
    }

    @media (max-width: 800px) {
        font-size: 36px;
        line-height: 3rem;
        text-align: center;
    }
}`;

export const LogoImgContainer = styled.div`
    margin-right: 20px;

    @media (max-width: 1200px) {
        margin-right: 0px;
`;

export const LogoImg = styled.img`
    width: 150px;
    height: auto;

    @media (max-width: 1200px) {
        width: 100px;
        height: auto;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 60px;
    margin-top: 0px;

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    @media (max-width: 800px) {
        width: 100%;
    }
`;

export const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 700px; /* Establecer la altura en píxeles */
    background-color: ${theme.color.anchor};

    @media (max-width: 1200px) {
        height: 650px;
    }

    @media (max-width: 1024px) {
        height: 600px;
    }

    @media (max-width: 800px) {
        height: 700px;
    }

    @media (min-height: 300px) {
        height: 700px;
    }
`;

export const FadingImage = styled.img`
    opacity: 0;
    animation: ${fadeIn} 1s ease-in-out forwards;
    width: 100%;
    height: 100%;
    
    @media (max-width: 600px) {
        background-color: ${theme.color.anchor};
        display: none;
    }
`;

export const Header2 = styled.h2<{ color?: string; backgroundColor?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 3rem 0;
    margin-top: 2rem;
    height: 100px;
    color: ${({ color }) => color || theme.color.black};
    background-color: ${({ backgroundColor }) => backgroundColor || theme.color.white};
    font-weight: 800,
    line-height: 4rem;
    font-size: 3rem;

    @media (max-width: 1024px) {
        font-size: 2rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 70%;
        margin: 0 auto;
        height: 80px;
    }

    @media (max-width: 600px) {
        font-size: 1.5rem;
        width: 100%;
        height: 60px;
    }
}`;

export const ChallengesHeader = styled.h2<{ color?: string; backgroundColor?: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    padding: 3rem 0;
    margin-top: 2rem;
    height: 100px;
    color: ${({ color }) => color || theme.color.black};
    background-color: ${({ backgroundColor }) => backgroundColor || theme.color.white};
    font-weight: 800,
    line-height: 4rem;
    font-size: 2.5rem;
    width: 70%;

    @media (max-width: 1024px) {
        font-size: 2rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 70%;
        margin: 0 auto;
        height: 80px;
    }

    @media (max-width: 600px) {
        font-size: 1.5rem;
        width: 100%;
        height: 60px;
    }
}`;

export const ChallengesSpan = styled.div`
    font-weight: 400;
    font-size: 1.7rem;
`;

export const BusinessSection = styled.div`
    margin-top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    height: auto;
    margin-bottom: 30px;
    animation: ${transitionUp} 1s ease-in-out forwards;

    @media (max-width: 1024px) {
        margin-bottom: 70px;
    }
`;

export const SolutionsSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
    margin: 0 auto;

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 60px 0;
`;

export const ChatBotContainer = styled.div`
    position: fixed;
    bottom: 4%;
    left: 2.5%;
    z-index: 999;
    @media (max-width: 800px) {
        left: 4%;
    }
`;