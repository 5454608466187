import { Button, Card, CardContent, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { BusinessInfoCardProps } from './types'
import theme from '../../theme/theme'
import { BodyText, BodyWrapper, BreakLine, CardContainer, Divider, ImageContainer, MainCard, TextImageWrapper, Title } from './styles'
import { useLocation, useNavigate } from 'react-router-dom'
import { CustomText } from '../CustomText'

const BusinessInfoCard: React.FC<BusinessInfoCardProps> = ({
	width = "auto",
	height = "auto",
	title,
	description,
	image = 'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
	backgroundColor = theme.color.white,
	dividerColor = theme.color.apricot,
	variant = 'purpose',
	zIndex = 0,
}) => {
	const lines = description.split(/\n/);
	console.log("lines", lines)
	const navigate = useNavigate();
    const location = useLocation();

	useEffect(() => {
			// Siempre que cambie la ubicación, desplázate al inicio de la página
			window.scrollTo(0, 0);
		}, [location]);

	if (variant === 'purpose') {
		return (
			<MainCard
				sx={{
					margin: '0 auto',
					backgroundColor: backgroundColor,
					borderRadius: '20px',
					zIndex: zIndex,
					boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
				}}
				width={width}
				height={height}
			>
				<CardContainer>
					<BodyWrapper>
						<Title>
							{title}
						</Title>
						<Divider dividerColor={dividerColor} backgroundColor={theme.color.white}  />
						<CustomText color={theme.color.indigo} content={description} />
					</BodyWrapper>
				</CardContainer>
			</MainCard>
		)
	} else {
		return (
			<MainCard
				sx={{
					backgroundColor: backgroundColor,
					borderRadius: '40px',
					zIndex: zIndex,
					marginBottom: "20px",
					boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
				}}
				width={width}
				height={height}
			>
				<TextImageWrapper>
					<ImageContainer
						src={image}
						alt='logo'
					/>
					<CardContainer>
						<BodyWrapper>
							<Title>
								{title}
							</Title>
							{/* </Typography> */}
							<Divider dividerColor={dividerColor} backgroundColor={theme.color.white} />
							<BodyText>
								{lines.map((line, index) => (
									<React.Fragment key={index}>
										{line}
										<BreakLine />
									</React.Fragment>
								))}
							</BodyText>
							{/* </Typography> */}
							<Button
								variant="contained"
								sx={{
									width: '100%',
									borderRadius: '12px',
									fontSize: '18px',
									backgroundColor: theme.color.lemon,
									marginBottom: "15px",
									color: theme.color.white,
									textTransform: 'none',
									'&:hover': {
										backgroundColor: theme.color.sheen,
									}
								}}
								onClick={() => navigate("/about")}
							>
								Conoce más
							</Button>
						</BodyWrapper>
					</CardContainer>
				</TextImageWrapper>
			</MainCard>
		)
	}

}

export default BusinessInfoCard