import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import App from "./App";
import { AppContextProvider } from "./services/app-context/app-context";
import theme from "./theme/theme";

ReactDOM.render(
  <AppContextProvider>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  </AppContextProvider>,
  document.querySelector("#root")
);
