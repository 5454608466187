import React from 'react'
import { ImageMosaicProps } from './types'
import { LeftWrapper, MosaicWrapper, Image, RightWrapper, Overlay, OverlayContent } from './styles'

const ImageMosaic: React.FC<ImageMosaicProps> = ({
    images,
    width = '90%',
}) => {
    const [selectedImage, setSelectedImage] = React.useState<string | null>(null);
    const [fitToScreen, setFitToScreen] = React.useState(false);

    const openImage = (image: string) => {
        document.body.style.overflow = 'hidden';
        setSelectedImage(image);
    };

    const closeImage = () => {
        document.body.style.overflow = 'auto';
        setSelectedImage(null);
        setFitToScreen(false);
    };

    return (
        <MosaicWrapper width={width}>
            <LeftWrapper>
                <Image src={images[0]} alt="Selected" type='left' onClick={() => openImage(images[0])} />
            </LeftWrapper>
            <RightWrapper>
                <Image src={images[1]} alt="Selected" onClick={() => openImage(images[0])}/>
                <Image src={images[2]} alt="Selected" type='top-right' onClick={() => openImage(images[0])}/>
                <Image src={images[3]} alt="Selected" onClick={() => openImage(images[0])}/>
                <Image src={images[4]} alt="Selected" type='bottom-right' onClick={() => openImage(images[0])}/>
            </RightWrapper>
            {selectedImage && (
                <Overlay onClick={closeImage}>
                    <OverlayContent fitToScreen={fitToScreen}>
                        <img src={selectedImage} alt="Selected" />
                    </OverlayContent>
                </Overlay>
            )}
        </MosaicWrapper>
    )
}

export default ImageMosaic