
import { createTheme, colors } from '@mui/material';

// A custom theme for this app
const theme = createTheme({
  color: {
    white: '#ffffff',
    black: '#000000',
    shadow: '#202020',
    charcoal: '#1E1E1E',
    graphite: '#2B2A2A',
    silver: '#D9D9D9',
    gray: '#7E7D7D',
    pale: '#EBEBEB',
    pearl: '#F4F4F4',
    whatsapp: '#2CB642',
    sheen: '#92B228',
    lemon: '#9FC131',
    honey: '#F2A007',
    apricot: '#F28705',
    indigo: '#642C80',
    anchor: '#1B2330',
    hWhatsapp: '#28A53D',
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#fafafa',
      dark: '#7e7d7d',
    },
    error: {
      main: colors.red.A400,
    },
    neutral: {
      main: colors.grey[500],
      darker: colors.grey[800],
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;