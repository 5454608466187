import React from 'react';
import { MapIframe } from './styles';

interface MapComponentProps {
  width?: string;
  height?: string;
  mapUrl?: string;
}

const MapComponent: React.FC<MapComponentProps> = ({
  width = '100%',
  height = '500px',
  mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.183594831933!2d-99.2782551!3d19.3612015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d20119f7f7653d%3A0x971897500b3e13d1!2sUPElectric%20Automatizaci%C3%B3n%2C%20S.A.%20de%20C.V.!5e0!3m2!1ses-419!2smx!4v1689047279918!5m2!1ses-419!2smx"
}) => {

  return (
    <MapIframe
      src={mapUrl}
      width={width}
      height={height}
      allowFullScreen={true}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></MapIframe>
  );
};

export default MapComponent;
