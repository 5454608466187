import styled from "styled-components";
import theme from "../../theme/theme";

export const InputDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

export const TitleContainer = styled.div`
    margin: -0.7rem 0.7rem 0 0.7rem;
`    

export const Title = styled.h1`
    font-size: 28px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: ${theme.color.black};
`