import React from 'react'
import { FadingImage, GalleryContainer, ImageContainer, LeftColumn, LeftServiceContainer, LeftServiceDescription, LeftServiceTitle, RightColumn, RightServiceContainer, RightServiceDescription, RightServiceTitle, ServiceDescription } from './styles';
import { About } from '../../components/About';
import ServicesBG from '../../assets/Backgrounds/ServiciosBG.png';
import { SingleGallery } from '../../components/SingleGallery';
import { Divider } from '@mui/material';
import { ChatBotContainer } from '../Home/styles';
import { ChatBotButton } from '../../components/ChatBotButton';

export const Services: React.FC = () => {
  const images: string[] = [
    "https://images.unsplash.com/photo-1564038057903-1d48b852b412?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80",
    "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80",
    "https://images.unsplash.com/photo-1535320903710-d993d3d77d29?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80",
    "https://images.unsplash.com/photo-1554260570-e9689a3418b8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2364&q=80",
    "https://images.unsplash.com/photo-1614028674026-a65e31bfd27c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80"
  ]
  return (
    <>
      <ImageContainer>
        <RightColumn>
          <FadingImage src={ServicesBG} width='10%' /> 
        </RightColumn>
        <LeftColumn>
          <About 
            title='Servicios' 
            description='Dentro de los servicios que ofrecemos está la consultoría en cuanto a la normativa y estándares de las redes eléctricas. Por otro lado, también brindamos proyectos de corrección o mantenimiento de las instalaciones eléctricas de los clientes. Así como proyectos de innovación para mejorar la gestión de la energía.'
            description2='Nuestras soluciones están enfocadas en tres aspectos; legal, operativo y financiero. Esto con el objetivo de presentar resultados eficaces y que sean validados desde diferentes ángulos, para así tener una solución más completa y customizada a cada cliente.'
          />
        </LeftColumn>
      </ImageContainer>

        <RightServiceContainer>
          <ServiceDescription>
            <RightServiceTitle>
              SISTEMAS DE CONTROL SUPERVISORIO DISTRIBUIDO, REDUNDANTES Y MULTIPLATAFORMA
            </RightServiceTitle>
            <Divider sx={{marginBottom: "1rem", marginTop: "-1.3rem", borderBottomWidth: "2px", borderRadius: "10px"}}/>
            <RightServiceDescription>
              Aprovechando su infraestructura existente, nuestros sistemas son totalmente abiertos y optimizan a los sistemas actuales dentro del (los) edificio (s).
            </RightServiceDescription>
          </ServiceDescription>
          <GalleryContainer>
            <SingleGallery images={images} width='100%'/>
          </GalleryContainer>
        </RightServiceContainer>

        <LeftServiceContainer>
          <GalleryContainer>
            <SingleGallery images={images} width='100%'/>
          </GalleryContainer>
          <ServiceDescription>
            <LeftServiceTitle>
              SISTEMAS SCADA EN UN SOLO EQUIPO
            </LeftServiceTitle>
            <Divider sx={{marginBottom: "1rem", marginTop: "-1.3rem", borderBottomWidth: "2px", borderRadius: "10px"}}/>
            <LeftServiceDescription>
              No requiere PC , y su alta inmunidad a Virus le brindan la robustez superior que lo posiciona como equipo con certificación LEED.
            </LeftServiceDescription>
          </ServiceDescription>
        </LeftServiceContainer>

        <RightServiceContainer>
          <ServiceDescription>
            <RightServiceTitle>
              CONTADORES DE ENERGÍA
            </RightServiceTitle>
            <Divider sx={{marginBottom: "1rem", marginTop: "-1.3rem", borderBottomWidth: "2px", borderRadius: "10px"}}/>

            <RightServiceDescription>
              Para disgregación de costos (cálculo de factura en tiempo real y por cada área o circuito), disponibles desde $3,900 MXN.
            </RightServiceDescription>
          </ServiceDescription>
          <GalleryContainer>
            <SingleGallery images={images} width='100%'/>
          </GalleryContainer>
        </RightServiceContainer>
        <ChatBotContainer>
            <ChatBotButton />
        </ChatBotContainer>
    </>
  )
}

export default Services;