import styled from 'styled-components';
import { BusinessInfoCardStylesProps, DimensionsProps } from './types';
import { Card, CardContent } from '@mui/material';
import theme from '../../theme/theme';

export const Divider = styled.hr<BusinessInfoCardStylesProps>`
    border: 1.5px solid ${({ dividerColor }) => dividerColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 10px;
`;

export const BodyWrapper = styled.div`
    padding: 30px 60px;

    @media (max-width: 1024px) {
        padding: 30px 40px;
    }

    @media (max-width: 600px) {
        padding: 30px 20px;
    }
`;

export const Title = styled.h1`
    font-size: 26px;
    font-weight: 600;
    margin: 0;
    color: ${theme.color.black};
`;

export const BodyText = styled.p`
    margin-top: 15px;
    margin-bottom: 30px;
    white-space: pre-line;
    color: ${theme.color.black};
    fontSize: 20px;
    fontWeight: 400;
    lineHeight: 40px;

    @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 35px;
    }

    @media (max-width: 600px) {
        font-size: 16px;
        line-height: 30px;
    }
`;

export const ImageContainer = styled.img`
    max-width: 350px;
    height: auto;
    object-fit: cover;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

export const TextImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const MainCard = styled(Card) <DimensionsProps>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};

    @media (min-height: 300px ) and (max-height: 780px) {
        width: 90%;
    }

    @media (max-width: 768px) {
        width: 90%;
    }
`;

export const CardContainer = styled.div` 
    @media (max-width: 1024px) {
        padding: 0;
    }
`;


export const BreakLine = styled.div`
    margin-bottom: 0.5rem;
`;

