import React, { useState } from 'react';
import { CarouselContainer, ImageContainer, Image, ArrowButton, CirclesContainer, Circle, BottomCarousel, TopCarousel } from './styles';
import { CarouselProps } from './types';
import { IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { BasicModal } from '../BasicModal';

const LogoCarousel: React.FC<CarouselProps> = ({
    clients,
    width = '80%',
    height = '200px',
}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentClient, setCurrentClient] = useState(null);

    const handlePrevClick = () => {
        setCurrentPage((prevPage) => (prevPage === 0 ? clients.length - 1 : prevPage - 1));
    };

    const handleNextClick = () => {
        setCurrentPage((prevPage) => (prevPage + 1) % clients.length);
    };

    const handleImageClick = (client: any) => {
        setCurrentClient(client);
        setModalOpen(true);
    }

    const startImageIndex = currentPage;
    const visibleImages = [];
    for (let i = 0; i < 4; i++) {
        const imageIndex = (startImageIndex + i) % clients.length;
        visibleImages.push(clients[imageIndex]);
    }

    return (
        <CarouselContainer height={height} width={width}>
            <TopCarousel>
                <ArrowButton onClick={handlePrevClick} left>
                    <IconButton>
                        <ArrowBackIosNew style={{ color: 'black', fontSize: 15 }} />
                    </IconButton>
                </ArrowButton>
                <ImageContainer height={height} width={width}>
                    {visibleImages.map((client, index) => (
                        <Image
                            key={ index }
                            src={ client.src }
                            alt={ client.alt }
                            onClick={() => handleImageClick(client)}
                        />
                    ))}
                </ImageContainer>
                <ArrowButton onClick={handleNextClick} right>
                    <IconButton>
                        <ArrowForwardIos style={{ color: 'black', fontSize: 15 }} />
                    </IconButton>
                </ArrowButton>
            </TopCarousel>
            <BottomCarousel>
                <CirclesContainer>
                    {clients.map((_, index) => (
                        <Circle key={index} active={index === currentPage} style={{ fontSize: 9 }} />
                    ))}
                </CirclesContainer>
            </BottomCarousel>
            {currentClient && (
                <BasicModal
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    client={currentClient}
                />
            )}
        </CarouselContainer>
    );
};

export default LogoCarousel;
