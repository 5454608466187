import React from 'react'
import { RouteObject, createBrowserRouter } from 'react-router-dom'
import { ROUTES } from './constants';
import PublicRouter from './PublicRouter';
import { AboutUs, Contact, Home, Projects, Services } from '../pages';

export const routes:RouteObject[] = [
  {    
    path: ROUTES.HOME,
    element: <PublicRouter />,
    children: [
      { index: true, element: <Home /> },
      { path: ROUTES.ABOUT_US, element: <AboutUs />},
      { path: ROUTES.CONTACT, element: <Contact />},
      { path: ROUTES.SERVICES, element: <Services />},
      { path: ROUTES.PROJECTS, element: <Projects />},
      { path: "*", element: <h1>Not Found</h1> },
    ]
  }
  ]

export const router = createBrowserRouter(routes);