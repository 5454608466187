import styled from 'styled-components';

export const MosaicWrapper = styled.div<{ width: string }>`
    display: flex;
    flex-direction: row;
    width: ${({ width }) => width || '100%'};
    height: auto;
    margin: 0 auto;

    @media (max-width: 480px) {
        width: 100%;
    }  
`;

export const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
`;

export const RightWrapper = styled.div` 
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 50%;
    height: auto;
`;

export const Image = styled.img<{ type?: string }>`
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 5px;
    cursor: pointer;
    transition: filter 0.3s ease;

    &:hover {
        filter: brightness(0.8);
        cursor: pointer;
    }

    ${({ type }) =>
        type === 'left' &&
        `
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;

            @media (max-width: 768px) {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            @media (max-width: 480px) {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        `
    }

  ${({ type }) =>
        type === 'top-right' &&
        `
            border-top-right-radius: 20px;

            @media (max-width: 768px) {
                border-top-right-radius: 10px;
            }

            @media (max-width: 480px) {
                border-top-right-radius: 5px;
            }
        `
    }

  ${({ type }) =>
        type === 'bottom-right' &&
        `
            border-bottom-right-radius: 20px;

            @media (max-width: 768px) {
                border-bottom-right-radius: 10px;
            }

            @media (max-width: 480px) {
                border-bottom-right-radius: 5px;
            }
        `
    }

    @media (max-width: 768px) {
        padding: 5px;
    }

    @media (max-width: 480px) {
        padding: 2px;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
`;

export const OverlayContent = styled.div<{ fitToScreen: boolean }>`
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  opacity: 0;
  animation: fade-in 0.3s ease-in-out forwards;

  img {
    width: ${props => (props.fitToScreen ? '100%' : 'auto')};
    height: ${props => (props.fitToScreen ? '100%' : 'auto')};
    max-width: ${props => (props.fitToScreen ? 'none' : '100%')};
    max-height: ${props => (props.fitToScreen ? 'none' : '100%')};
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
