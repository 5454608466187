import {
  ElectricText,
  FooterBelowContainer,
  FooterContainer,
  FooterCopyrightContainer,
  FooterCopyrightIcon,
  FooterCopyrightText,
  FooterDivider,
  FooterLink,
  FooterLinkContainer,
  FooterLinkWrapper,
  FooterLogoImg,
  FooterLogoImgContainer,
  FooterUpperContainer,
  FooterWrapper,
  LogoContainer,
  TextContainer,
  UPText,
} from "./styles";

import CopyrightIcon from "@mui/icons-material/Copyright";
import UPElectricLogo from "../../assets/NoTextLogo.png";
import React, { useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import { Facebook, LinkedIn } from "@mui/icons-material";
import theme from "../../theme/theme";
import { useLocation, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

/**
 * Footer for all pages in the project. Does not receive any arguments.
 */

const Footer = () => {
  // const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Siempre que cambie la ubicación, desplázate al inicio de la página
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterUpperContainer>
          <LogoContainer onClick={() => navigate("/")}>
            <FooterLogoImgContainer>
              <FooterLogoImg src={UPElectricLogo} />
            </FooterLogoImgContainer>
            <TextContainer>
              <UPText>UP</UPText>
              <ElectricText>Electric</ElectricText>
            </TextContainer>
          </LogoContainer>

          <FooterLinkWrapper>
            <FooterLinkContainer>
              <FooterLink onClick={() => navigate("/about")}>Nosotros </FooterLink>
              <FooterLink onClick={() => navigate("/products")}>Soluciones</FooterLink>
              <FooterLink onClick={() => navigate("/services")}>Servicios</FooterLink>
              <FooterLink onClick={() => navigate("/projects")}>Proyectos</FooterLink>
              <FooterLink onClick={() => navigate("/contact")}>Contacto</FooterLink>
            </FooterLinkContainer>
          </FooterLinkWrapper>
        </FooterUpperContainer>

        <FooterBelowContainer>
          <FooterDivider />
          {isMobile ? (
            <>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>
                  <IconButton
                    sx={{
                      "&:hover": {
                        color: theme.color.white,
                        transition: "color 0.4s ease-in-out",
                        opacity: 0.7,
                        svg: {
                          color: theme.color.honey,
                          transition: "color 0.4s ease-in-out",
                        },
                      },
                    }}
                    style={{ marginTop: "10px" }}
                  >
                    <Facebook sx={{ fontSize: 36, color: "#a0a2aa" }} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    sx={{
                      "&:hover": {
                        color: theme.color.white,
                        transition: "color 0.4s ease-in-out",
                        opacity: 0.7,
                        svg: {
                          color: theme.color.honey,
                          transition: "color 0.4s ease-in-out",
                        },
                      },
                    }}
                    style={{ marginTop: "10px" }}
                  >
                    <LinkedIn sx={{ fontSize: 36, color: "#a0a2aa" }} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item>
                <FooterCopyrightContainer>
                  <FooterCopyrightIcon>
                    <CopyrightIcon sx={{ fontSize: 15 }} />
                  </FooterCopyrightIcon>
                  <FooterCopyrightText>
                    &nbsp;2023 UPElectric Automatización. Todos los derechos
                    reservados.
                  </FooterCopyrightText>
                </FooterCopyrightContainer>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                margin-top = "1rem"
                spacing={1}
              >
                <Grid item>
                  <FooterCopyrightContainer>
                    <FooterCopyrightIcon>
                      <CopyrightIcon sx={{ fontSize: 15 }} />
                    </FooterCopyrightIcon>
                    <FooterCopyrightText>
                      &nbsp;2023 UPElectric Automatización. Todos los derechos
                      reservados.
                    </FooterCopyrightText>
                  </FooterCopyrightContainer>
                </Grid>

                <Grid item>
                  <IconButton
                    sx={{
                      "&:hover": {
                        color: theme.color.white,
                        transition: "color 0.4s ease-in-out",
                        opacity: 0.7,
                        svg: {
                          color: theme.color.honey,
                          transition: "color 0.4s ease-in-out",
                        },
                      },
                    }}
                  >
                    <Facebook sx={{ fontSize: 30, color: "#a0a2aa" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      "&:hover": {
                        color: theme.color.white,
                        transition: "color 0.4s ease-in-out",
                        opacity: 0.7,
                        svg: {
                          color: theme.color.honey,
                          transition: "color 0.4s ease-in-out",
                        },
                      },
                    }}
                    href="https://www.linkedin.com/in/upelectric-automatización-05b736284" target="_blank"
                  >
                    <LinkedIn sx={{ fontSize: 30, color: "#a0a2aa" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          )}
        </FooterBelowContainer>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
