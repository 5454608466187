import React from "react";
import { InfoProps } from "./types";
import { InfoImg, ShowDescription, ShowIcon, ShowLabel, ShowTitle } from "./styles";
import * as MUIcon from "@mui/icons-material";
import FinImg from "../../assets/Info/Financiero.png";
import LegImg from "../../assets/Info/Legal.png";
import OpImg from "../../assets/Info/Operativo.png";
import { CustomText } from "../CustomText";
import theme from "../../theme/theme";

const Info: React.FC<InfoProps> = ({ content, type}) => {


    return (
        <div>
        <ShowLabel>
            <ShowIcon>
                <InfoImg src={type === "Financiero" ? FinImg : type === "Legal" ? LegImg : OpImg} />
            </ShowIcon>
            <ShowTitle>{type === "Financiero" ? "Financiero" : type === "Legal" ? "Legal" : "Operativo"}</ShowTitle>
            <CustomText color={theme.color.honey} content={content} />
            {/* <ShowDescription>{content}</ShowDescription> */}
        </ShowLabel>
        </div>
    );
};

export default Info;
