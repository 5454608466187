import styled from "styled-components";

export const ShowDescription = styled.p`
  font-size: 18px;
  font-weight: regular;
  margin: 15px 0 0 0;
  padding: 0;
`;

export const BreakLine = styled.div`
    margin-bottom: 0.5rem;
`;
