import styled, { css } from "styled-components";
import { FiberManualRecord } from "@mui/icons-material";
import { ImageProps } from "./types";

export const CarouselContainer = styled.div<{ height: string; width: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow: hidden;
  position: relative;
  margin: 0 auto;
`;

export const TopCarousel = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  transition: transform 0.3s ease;
`;

export const BottomCarousel = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const ArrowButton = styled.button<{ left?: boolean; right?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 24px;
  transition: opacity 0.3s;
  opacity: 1;

  ${({ left }) =>
    left &&
    css`
      left: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 100;
    `}

  ${({ right }) =>
    right &&
    css`
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    `}

  &:hover {
    opacity: 1;
  }
`;

export const ImageContainer = styled.div<{ height: string; width: string }>`
  width: ${({ width }) => width};
  transition: transform 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  gap: 10px;
  padding: 0 20px;

  @media (max-width: 768px) {
    justify-content: space-between;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-padding: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    & > * {
      scroll-snap-align: start;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 480px) {
    width: 60%;

`;

export const Image = styled.img<ImageProps>`
  object-fit: cover;
  margin: 0;
  width: 150px;
  height: 120px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    scale: 1.05;
    transition: scale 0.3s ease-in-out;
  }

@media (max-width: 480px) {
  width: 175px;
  height: 155px;
  &:hover {
    scale: 1.1;
    transition: scale 0.5s ease-in-out;
  }
  
`;

export const CirclesContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Circle = styled(FiberManualRecord)<{ active: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  margin: 0 2px;
  color: #000;
`;
