import styled from 'styled-components';

export const VideoContainer = styled.div<{ height: string, width: string }>`
  border-radius: 15px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const VideoIframe = styled.iframe<{ height: string, width: string}>`
  border-radius: 15px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

