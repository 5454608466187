import React from 'react'
import { ProjectSummary, ProjectsWrapper, Title, Divider } from './styles'
import { ProjectDescription } from '../../components/ProjectDescription'
import theme from '../../theme/theme'
import { ChatBotContainer } from '../Home/styles'
import { ChatBotButton } from '../../components/ChatBotButton'

const Projects = () => {
    return (
        <>
            <ProjectsWrapper>
                <Title>Proyectos</Title>
                <ProjectSummary>
                    Nuestra experiencia profesional está acreditada por numerosos proyectos
                </ProjectSummary>
                <Divider />
            </ProjectsWrapper>
            <ProjectDescription
                projects={[
                    {
                        client: 'ITESM',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.',
                        images: [
                            'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                            'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                            'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                            'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                            'https://i.natgeofe.com/n/2a832501-483e-422f-985c-0e93757b7d84/6.jpg?w=1272&h=954',
                        ]
                    },
                    {
                        client: 'Lilly',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                    },
                    {
                        client: 'ABC',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                    },
                    {

                        client: 'ITAM',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                    },
                    {
                        client: 'UVM',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                    },
                    {
                        client: 'Caprice',
                        projectDescription: 'Lorem ipsum dolor sit amet. Vel possimus quia ut voluptas voluptatum rem enim quod aut maiores dolor eum maxime ullam. Ut incidunt dolores sit laboriosam voluptatibus sed consequatur adipisci ad repellendus veniam.'
                    },
                ]}
            />
            <ChatBotContainer>
                <ChatBotButton />
            </ChatBotContainer>
        </>
    )
}

export default Projects