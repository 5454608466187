import styled, { keyframes } from "styled-components";
import theme from "../../theme/theme";

export const HeaderWrapper = styled.div`
    background-color: ${theme.color.shadow};
    box-shadow: 0px 1px 7px 0px ${theme.color.shadow};
    width: 100%;
    margin-bottom: 0px;
`;

export const HeaderContainer = styled.div`
    width: 100%;
    height: 80px;
    background-color: ${theme.color.shadow};
`;

export const HeaderLogoImgContainer = styled.div`
    margin: 1em 0 1em 1em;
`;

export const HeaderLogoImg = styled.img`
    height: 2.7em;
    width: auto;
    margin-left: 4rem;

    @media (max-width: 1024px) {
        margin-left: 0;
`;

export const HeaderLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const HeaderLinkContainer = styled.div<{ showSecondHeader?: boolean }>`
  margin: 0;
  margin-right: 4rem;
  opacity: ${({ showSecondHeader }) => (showSecondHeader ? 0 : 1)};
  @media (max-width: 1024px) {
    display: none;
    }
`;


export const HeaderLink = styled.a`
    cursor: pointer;
    text-decoration: none;
    color: #f5f5f5;
    margin: 1em;
    font-size: 1em;
    &:hover {
        color: ${theme.color.honey};
        transition: color 0.4s ease;
    }
`;

export const FooterCopyrightContainer = styled.div`
    color: #a0a2aa;
    text-align: left;
    display: flex;
    align-items: flex-start;
`;


export const LogoContainer = styled.div<{ showSecondHeader?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: -0.5em;
    margin-left: 1rem;
    opacity: ${({ showSecondHeader }) => (showSecondHeader ? 0 : 1)};

    &:hover {
        cursor: pointer;
    }
`;

export const TextContainer = styled.div`
    display: inline-block;
`;

export const UPText = styled.span`
    color: ${theme.color.honey};
    font-size: 1.5em;
    font-weight: 700;
`;

export const ElectricText = styled.span`
    color: ${theme.color.white};
    font-size: 1.5em;
    font-weight: 700;
`;

export const MobileMenuButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1025px) {
        display: none;
    }

    @media (max-width: 1024px) {
        margin-right: 2rem;
    }
`;

export const MobileMenuOptionsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #202020;
    width: 100%;
    position: fixed;

    @media (min-width: 1024px) {
        display: none;  
    }
`;

export const MobileMenuList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
`;

export const MobileMenuItem = styled.a`
    cursor: pointer;
    text-decoration: none;
    color: #f5f5f5;
    margin: 5px 0;
    font-size: 1em;

    &:hover {
        color:${theme.color.honey};
        transition: color 0.4s ease;
    }
`;

const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

export const SecondHeaderContainer = styled.div<{showSecondHeader?: boolean}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    // background-color: ${theme.color.shadow};
    // box-shadow: 0px 1px 7px 0px ${theme.color.shadow};
    font-family: "Inter", sans-serif;
    z-index: 1000;
    // animation: ${({ showSecondHeader }) => (showSecondHeader ? slideDown : slideUp)} 0.3s ease-in-out forwards;
    // opacity: ${({ showSecondHeader }) => (showSecondHeader ? '1' : '0')};
    transition: opacity 0.3s ease-in-out;
  `;