import React, { useState } from 'react';
import {
    GalleryContainer,
    LeftColumn,
    RightColumn,
    BigImage,
    SmallImage,
    Overlay,
    OverlayContent,
    SmallImagesWrapper,
} from './styles';

interface GalleryProps {
    images: string[];
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const [fitToScreen, setFitToScreen] = useState(false);

    const openImage = (image: string) => {
        document.body.style.overflow = 'hidden';
        setSelectedImage(image);
    };

    const closeImage = () => {
        document.body.style.overflow = 'auto';
        setSelectedImage(null);
        setFitToScreen(false);
    };

    return (
        <GalleryContainer>
            <LeftColumn>
                <BigImage src={images[0]} onClick={() => openImage(images[0])} />
                <SmallImagesWrapper>
                    <SmallImage src={images[1]} onClick={() => openImage(images[1])} />
                    <SmallImage src={images[2]} onClick={() => openImage(images[2])} />
                </SmallImagesWrapper>
            </LeftColumn>
            <RightColumn>
                <SmallImagesWrapper>
                    <SmallImage src={images[3]} onClick={() => openImage(images[3])} />
                    <SmallImage src={images[4]} onClick={() => openImage(images[4])} />
                </SmallImagesWrapper>
                <BigImage src={images[5]} onClick={() => openImage(images[5])} />
            </RightColumn>
            {selectedImage && (
                <Overlay onClick={closeImage}>
                    <OverlayContent fitToScreen={fitToScreen}>
                        <img src={selectedImage} alt="Selected" />
                    </OverlayContent>
                </Overlay>
            )}
        </GalleryContainer>
    );
};

export default Gallery;
