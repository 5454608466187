import styled from "styled-components";
import theme from "../../theme/theme";

export const ContactWrapper = styled.div`
  margin: 3rem 15% 0 15%;
  font-family: 'Inter', sans-serif;

  @media (max-width: 400px) {
    margin: 3rem 9% 0 9%;
  }

`;

export const ContactTitle = styled.h1`
  font-size: 48px;
  font-weight: 800;
  margin-bottom: -0.6rem;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: 4rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;  

export const LeftColumn = styled.div`
  flex-basis: 60%;

  @media (max-width: 1024px) {
    flex-basis: 100%;
  }
`;  

export const RightColumn = styled.div`
  flex-basis: 40%;

  @media (max-width: 1024px) {
    flex-basis: 100%;
  }
`;

export const ContactMedia = styled.div`
  margin: 2rem 2rem;
  max-width: 500px;

  @media (max-width: 400px) {
    margin: 2rem 0;
  }
`; 

export const Description = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: -0.2rem;
`; 

export const Subtitle = styled.h2`
  font-size: 28px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: ${theme.color.black};
  margin-bottom: -0.4rem;
  margin-top: 2.5rem;
`;

export const BusinessHours = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 2rem;
  width: 90%;
  align-items: end;

  @media (max-width: 400px) {
    margin: 0;
    width: 100%;
  }
`;

export const BusinessHoursLColumn = styled.div`
  flex-basis: 50%;

  @media (max-width: 400px) {
    flex-basis: 40%;
  }
`;

export const BusinessHoursRColumn = styled.div`
  flex-basis: 50%;

  @media (max-width: 400px) {
    flex-basis: 60%;
  }
`;

export const Days = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
`;

export const Hours = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
`;

export const CardContent = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: ${theme.color.black};
`;

export const CardTitle = styled.h3`
  margin: 0 0 -0.5rem 0;
  font-size: 28px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: ${theme.color.black};
`;

export const CardContainer = styled.div`
  margin: 1.8rem 0;
`;

export const ContactImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 330px;
  border-radius: 20px;
`;