import styled, { keyframes } from "styled-components";
import theme from "../../theme/theme";

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const ImageContainer = styled.div`
  width: 100%;
  background-color: ${theme.color.anchor};
  display: flex;
  align-items: center;
  gap: 5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
    align-items: unset;
    padding-top: 2rem;
  }

  @media (max-width: 400px) {
    padding-top: 0;
  }
`;

export const RightColumn = styled.div`
  flex-basis: 45%;
  margin: 4rem 0 2rem 0;
  order: 1;
  @media (max-width: 768px) {
    width: 80%;
    order: 2;
    display: flex;
    justify-content: flex-start;
    margin: 0;
  }
`;

export const LeftColumn = styled.div`
  flex-basis: 55%;
  order: 2;

  @media (max-width: 768px) {
    flex-basis: 100%;
    margin: 0 10%;
    order: 1;
  }

  @media (max-width: 400px) {
    margin: 0 9%;
  }
`;

export const FadingImage = styled.img`
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out forwards;
  width: 100%;
  height: auto;
`;

export const RightServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4rem;
  background-color: ${theme.color.pearl};
  font-family: "Inter", sans-serif;
  padding: 3rem 10%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }

`;

export const RightServiceTitle = styled.h2`
  font-size: 30px;
  text-align: right;
  font-weight: 800;
  margin-top: 0;
  color: ${theme.color.charcoal};

  @media (max-width: 400px) {
    font-size: 25px;
  }
`;

export const RightServiceDescription = styled.p`
  font-size: 20px;
  text-align: right;
  font-weight: 400;
  color: ${theme.color.black};
`;

export const GalleryContainer = styled.div`
  flex-basis: 35%;
`;

export const ServiceDescription = styled.div`
  flex-basis: 65%;
`;

export const LeftServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4rem;
  background-color: ${theme.color.pale};
  font-family: "Inter", sans-serif;
  padding: 3rem 10%;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 2rem;
  }
`;

export const LeftServiceTitle = styled.h2`
  font-size: 30px;
  text-align: left;
  font-weight: 800;
  margin-top: 0;
  color: ${theme.color.charcoal};

  @media (max-width: 400px) {
    font-size: 25px;
  }
`;

export const LeftServiceDescription = styled.p`
  font-size: 20px;
  text-align: left;
  font-weight: 400;
  color: ${theme.color.black};
`;
