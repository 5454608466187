import styled from 'styled-components';

export const GalleryContainer = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 50% 50%;
`;


export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra las imágenes horizontalmente */
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra las imágenes horizontalmente */
`;

export const SmallImagesWrapper = styled.div`
  display: flex;
  flex: 1; /* Ocupa el espacio disponible */
  justify-content: space-between; /* Espacio entre las imágenes pequeñas */
`;

export const BigImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(0.8);
    cursor: pointer;
  }
`;

export const SmallImage = styled.img`
  width: 50%;
  height: auto;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(0.7);
    cursor: pointer;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const OverlayContent = styled.div<{ fitToScreen: boolean }>`
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  opacity: 0;
  animation: fade-in 0.3s ease-in-out forwards;

  img {
    width: ${props => (props.fitToScreen ? '100%' : 'auto')};
    height: ${props => (props.fitToScreen ? '100%' : 'auto')};
    max-width: ${props => (props.fitToScreen ? 'none' : '100%')};
    max-height: ${props => (props.fitToScreen ? 'none' : '100%')};
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

