import styled, { keyframes } from 'styled-components';
import theme from '../../theme/theme';

const appearAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 5px 0 12px;
  opacity: 0;
  transform: translateY(10px);
  animation: ${appearAnimation} 0.5s ease-in-out 0.3s forwards;
`;

export const ButtonText = styled.span`
  color: ${theme.color.white};
  text-align: left;
  font-size: 15px;
  opacity: 0;
  animation: ${appearAnimation} 0.5s ease-in-out 0.2s forwards;
  margin-bottom: -7px;
`;

export const SubButtonText = styled.span`
  color: ${theme.color.white};
  font-size: 12px;
  text-align: left;
  font-weight: 200;
  opacity: 0;
  animation: ${appearAnimation} 0.5s ease-in-out 0.2s forwards;
`;
