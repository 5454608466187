import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import { WhatsApp } from '@mui/icons-material';
import { ButtonText, Container, SubButtonText } from './styles';
import theme from '../../theme/theme';


const ChatBotButton: React.FC = ({
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Fab
      color="primary"
      style={{
        backgroundColor: theme.color.whatsapp,
        transition: 'transform 0.3s, width 0.3s',
        width: isHovered ? '240px' : '60px',
        height: '60px', 
        borderRadius: '30px',
        transform: isHovered ? 'scale(1.1)' : 'scale(1)',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      variant="extended"
      sx={{
        textTransform: 'none',
      }}
    >
      {isHovered ? (
        <>
          <WhatsApp style={{ color: theme.color.white }} sx={{fontSize:"35px"}}/>
          <Container>
          <ButtonText>Chatbot de WhatsApp</ButtonText>
          <SubButtonText>Iniciar Chat</SubButtonText>
          </Container>
        </>
      ) : (
        <WhatsApp style={{ color: theme.color.white }} sx={{fontSize:"35px"}}/>
      )}
    </Fab>
  );
};

export default ChatBotButton;
