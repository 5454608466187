import React, { useEffect, useState } from 'react';
import {
  HeaderContainer,
  HeaderLogoImg,
  HeaderLinkContainer,
  HeaderLink,
  MobileMenuButton,
  TextContainer,
  UPText,
  ElectricText,
  LogoContainer,
  HeaderLogoImgContainer,
  SecondHeaderContainer,
} from './styles';
import MotoSpotWhite from "../../assets/NoTextLogo.png";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import theme from '../../theme/theme';
import { ElectricBolt, ElectricalServices, Forum, Groups, History } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    // Siempre que cambie la ubicación, desplázate al inicio de la página
    window.scrollTo(0, 0);
  }, [location]);

  const list = () => {
    const handleButtonClick = (path: any) => {
      navigate(path);
    };

    return (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={handleDrawerToggle}
      >
        <List>
          {[
            { text: 'Nosotros', icon: <Groups sx={{color: theme.color.white}} />, path: '/about' },
            { text: 'Soluciones', icon: <ElectricalServices sx={{color: theme.color.white}}/>, path: '/products' },
            { text: 'Servicios', icon: <ElectricBolt sx={{color: theme.color.white}}/>, path: '/services' },
            { text: 'Proyectos', icon: <History sx={{color: theme.color.white}}/>, path: '/projects' },
            { text: 'Contacto', icon: <Forum sx={{color: theme.color.white}}/>, path: '/contact' },
          ].map(({ text, icon, path }, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.2)', paddingLeft: "10px" }}
            >
              <ListItemButton 
                sx={{
                  '&:hover': {
                    color: theme.color.honey,
                    transition: "color 0.3s ease-in-out",
                    "svg": {
                      color: theme.color.honey,
                      transition: "color 0.3s ease-in-out",
                    }
                  },
                }}
                onClick={() => handleButtonClick(path)}
              >
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <>
      <HeaderContainer/>
      <SecondHeaderContainer>
        <Box
          sx={{
            backdropFilter: "blur(15px)",
            backgroundColor: "rgba(10, 10, 10, 0.9)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "80px",
            fontFamily: "Inter, sans-serif",
            zIndex: 1000,
            boxShadow: "0px 1px 7px 0px #202020",
          }}
        >
          <LogoContainer onClick={() => navigate("/")}>
            <HeaderLogoImgContainer>
              <HeaderLogoImg src={MotoSpotWhite} />
            </HeaderLogoImgContainer>
            <TextContainer>
              <UPText>UP</UPText>
              <ElectricText>Electric</ElectricText>
            </TextContainer>
          </LogoContainer>
          <HeaderLinkContainer>
            <HeaderLink onClick={() => navigate("/about")}>Nosotros</HeaderLink>
            <HeaderLink onClick={() => navigate("/products")}>Soluciones</HeaderLink>
            <HeaderLink onClick={() => navigate("/services")}>Servicios</HeaderLink>
            <HeaderLink onClick={() => navigate("/projects")}>Proyectos</HeaderLink>
            <HeaderLink onClick={() => navigate("/contact")}>Contacto</HeaderLink>
          </HeaderLinkContainer>
          <MobileMenuButton onClick={handleDrawerToggle}>
            <MenuIcon style={{ color: "#fff" }} />
          </MobileMenuButton>
        </Box>
      </SecondHeaderContainer>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            background: '#2B2A2A',
            color: '#fff',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            py: 3,
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              color: '#fff',
              position: 'absolute',
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {list()}
      </Drawer>
    </>
  );
};

export default Header;
