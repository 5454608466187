import React from 'react';
import { AboutProps } from './types';
import UPElectricLogo from "../../assets/NoTextLogo.png";
import { AboutWrapper, Description, ElectricText, LogoContainer, LogoImg, LogoImgContainer, TextLogoContainer, Title, UPText } from './styles';


const About: React.FC<AboutProps> = ({
  title = 'Title',
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ipsum luctus, rhoncus quam ut, aliquet purus. Ut vel est eu nunc tincidunt viverra ut a dui. Ut eget tortor lacinia turpis porta ultricies ut ut purus. Phasellus porttitor mi id nibh ornare, in mollis orci blandit. Vivamus scelerisque nulla orci, sed molestie tellus aliquam sit amet. Phasellus accumsan iaculis semper. Quisque non diam sit amet elit laoreet condimentum consectetur eget lacus. Integer id maximus nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus quam felis, fringilla et orci a, lobortis fringilla lacus. Donec sed odio tortor. Fusce dignissim, massa sed placerat congue, purus augue iaculis lacus, sed ornare leo nibh a eros. Morbi scelerisque cursus ultrices. Praesent sapien ipsum, bibendum quis fermentum eget, hendrerit at odio. Nullam maximus eget arcu eget lobortis. Donec sed imperdiet tellus",
  description2 = "Nullam ac vestibulum tellus. Sed vitae lacinia justo. Quisque mattis ipsum non elit cursus semper. Cras accumsan nulla vel quam bibendum, id egestas nibh feugiat. Ut est ex, aliquam sed tellus at, suscipit efficitur augue. Suspendisse potenti. Quisque ut eros at nunc sollicitudin porta ac elementum mauris. Pellentesque id libero turpis. Nullam est ipsum, efficitur eu tellus a, venenatis pharetra ipsum."

}) => {
  return (
    <AboutWrapper>
      <LogoContainer>
        <LogoImgContainer>
          <LogoImg src={UPElectricLogo} />
        </LogoImgContainer>
        <TextLogoContainer>
          <UPText>UP</UPText>
          <ElectricText>Electric</ElectricText>
        </TextLogoContainer>
      </LogoContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Description>{description2}</Description>
    </AboutWrapper>
  )
};

export default About;