import React from 'react'
import { MapComponent } from '../../components/MapComponent'
import { BusinessHours, BusinessHoursLColumn, BusinessHoursRColumn, CardContainer, CardContent, CardTitle, ContactImage, ContactMedia, ContactTitle, ContactWrapper, Days, Description, Hours, LeftColumn, MainContainer, RightColumn, Subtitle } from './styles';
import { ContactCard } from '../../components/ContactCard';
import { Button, Divider } from '@mui/material';
import { TextWithIcon } from '../../components/TextWithIcon';
import { Card } from '../../components/Card';
import theme from '../../theme/theme';
import { WhatsApp } from '@mui/icons-material';

import ContactImg from '../../assets/Backgrounds/Contacto.jpg';

export const Contact: React.FC = () => {
  return (
    <>
      <ContactWrapper>
        <ContactTitle>Contáctanos</ContactTitle>
        <MainContainer>
          <LeftColumn>
            <Description>
              Estamos aquí para apoyarte en lo que necesites
            </Description>
            <ContactMedia>
              <TextWithIcon icon='Phone' text='+52 (55) 4169 2725' size='Medium' />
              <TextWithIcon icon='Mail' text='ventas@upelectric.com.mx' size='Medium'/>
              <TextWithIcon icon='LocationOn' text='Av. Vasco de Quiroga 3900 Torre A. Piso 10 Contadero Cuajimalpa de Morelos 01219 Ciudad de México, CDMX México' size='Medium'/>
            </ContactMedia>
            <Divider sx={{marginBottom: "3rem", borderBottomWidth: "2px", borderRadius: "10px"}}/>
            <ContactCard height='auto' width='100%' />
          </LeftColumn>

          <RightColumn>
            <ContactImage src={ContactImg}/>
            <Subtitle>Horarios de atención</Subtitle>
            <BusinessHours>
              <BusinessHoursLColumn>
                <Days>Lunes a Viernes</Days>
                <Days>Sábado</Days>
                <Days>Domingo</Days>
              </BusinessHoursLColumn>
              <BusinessHoursRColumn>
                <Hours>8:30 - 18:30 hrs</Hours>
                <Hours>Cerrado</Hours>
                <Hours>Cerrado</Hours>
              </BusinessHoursRColumn>
            </BusinessHours>
            <CardContainer>
              <Card height='auto' width='100%'>
                <CardTitle>WhatsApp</CardTitle>
                <CardContent>
                  Solicita ayuda a nuestro chatbot de WhatsApp
                </CardContent>
                <Button
                  variant="contained"
                  startIcon={<WhatsApp />}
                  sx={{
                    width: '100%',
                    marginTop: '0.5rem',
                    fontSize: '18px',
                    borderRadius: '12px',
                    backgroundColor: theme.color.whatsapp,
                    color: theme.color.white,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: theme.color.hWhatsapp,
                    }
                  }}
                >
                    Iniciar Chat
                </Button>
              </Card>
            </CardContainer>
          </RightColumn>
        </MainContainer>
      </ContactWrapper>
      <MapComponent />
    </>

  )
}

export default Contact;