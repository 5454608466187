import styled from 'styled-components';
import theme from '../../theme/theme';

export const ProjectsWrapper = styled.div`
    background-color: ${theme.color.anchor};
    font-family: "Inter", sans-serif;
    padding: 4rem 6rem 0 6rem;

    @media (max-width: 768px) {
        padding: 2rem 4rem 0 4rem;
    }

    @media (max-width: 600px) {
        padding: 2rem 2rem 0 2rem;
    }
}`;

export const Title = styled.h1`
  color: ${theme.color.white};
  font-size: 72px;
  font-weight: 900;
  line-height: 98px;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 45px;
  }
`;

export const ProjectSummary = styled.p`
    margin: 0;
    color: ${theme.color.silver};
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 1em;

    @media (max-width: 600px) {
        font-size: 18px;
    }
`;

export const Divider = styled.hr`
    border: none;
    border-top: 1px solid ${theme.color.gray};
    margin: 0;
    padding-bottom: 1em;
    width: 100%;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;