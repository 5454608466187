import styled from "styled-components";
import { CardProp } from "./types";

export const WhiteCard = styled.div<CardProp>`
    background-color: ${({ colorBackground }) => colorBackground ?? "#ffff"};
    border-radius: 14px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  
    display: table;
    width: ${({ width }) => width ?? "auto"};
    height: ${({ height }) => height ?? "auto"};
    min-width: 16em;
    min-height: 10em;
    padding: 30px;
    color:  ${({ color }) => color ?? "#ffff"};
`;