import React, { useState } from "react";
import { ContactCardProps } from "./types";
import { Card } from "../Card";
import { InputDiv, Title, TitleContainer } from "./styles";
import { Box, Button, LinearProgress, TextField } from "@mui/material";
import theme from "../../theme/theme";

const ContactCard: React.FC<ContactCardProps> = ({
    height = "auto",
    width = "500px",
}) => {

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Card width={width} height={height}>
                        <TitleContainer>
                            <Title>Comunícate con nosotros</Title>
                        
                        <form >
                            <InputDiv>
                                <TextField
                                    label="Nombre"
                                    name="first_name"
                                    type="text"
                                    size="small"
                                    required
                                    variant="outlined"
                                    sx={{
                                        width: "100%"
                                    }}
                                    InputProps={{
                                        style: {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                                <TextField
                                    label="Apellido"
                                    name="last_name"
                                    size="small"
                                    type="text"
                                    required
                                    variant="outlined"
                                    sx={{
                                        width: "100%"
                                    }}
                                    InputProps={{
                                        style: {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                                <TextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    required
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        width: "100%"
                                    }}
                                    InputProps={{
                                        style: {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                                <TextField
                                    label="Teléfono"
                                    name="phone"
                                    type="number"
                                    required
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        width: "100%",
                                        "& input[type=number]": {
                                            "-moz-appearance": "textfield", // Firefox
                                            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                                              "-webkit-appearance": "none", // Safari, Chrome, Edge
                                              margin: 0
                                            },
                                            "&[type=number]": {
                                              "-moz-appearance": "textfield" // Firefox (fallback)
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                            </InputDiv>
                            <TextField
                                required
                                multiline
                                label="Escríbenos tus comentarios"
                                size="small"
                                name="description"
                                type="text"
                                rows={5}
                                sx={{ width: '100%', marginTop: '1.5rem' }}
                                InputProps={{
                                    style: {
                                        borderRadius: '12px'
                                    },
                                }}
                            />
                            <div>
                                <Button
                                  variant="contained"
                                  sx={{
                                    width: '100%',
                                    marginTop: '1.7rem',
                                    borderRadius: '12px',
                                    marginBottom: '-1.3rem',
                                    fontSize: '18px',
                                    backgroundColor: theme.color.lemon,
                                    color: theme.color.white,
                                    textTransform: 'none',
                                    '&:hover': {
                                      backgroundColor: theme.color.sheen,
                                    }
                                  }}
                                >
                                    Enviar
                                </Button>
                            </div>
                            <br />
                            {isLoading && <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>}
                        </form>
                        </TitleContainer>
                    </Card>
  )
};

export default ContactCard;