import { createContext, useContext, useState } from 'react';
import { AppContextProps } from './types';

const AppContext = createContext<undefined>(undefined);

export const AppContextProvider = ({ children }: AppContextProps) => {

  return (
    <AppContext.Provider value={undefined}>
      {children}
    </AppContext.Provider>

  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};
